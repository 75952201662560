var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-apex-charts',{attrs:{"height":350,"options":Object.assign({}, _vm.chartOptions,
      {xaxis: {
        categories: _vm.dates
      },
      yaxis: {
        labels: {
          formatter: function formatter(val) {
            return _vm.CONVERT_HM(val)
          },
        },
        title: {
          text: _vm.$t('Time'),
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function formatter(val) {
          return _vm.CONVERT_HM(val)
        }
      }}),"series":[{
      name: _vm.$t('reports.total-hours'),
      data: _vm.seconds,
    }]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }