<template>
  <b-overlay :show="processing">
    <b-card>
      <bar-graph
        :v-if="graphData.series.data && graphData.series.data.length"
        :categories="graphData.categories"
        :series="graphData.series"
      />
    </b-card>
  </b-overlay>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import BarGraph from '@/views/common/graphs/HoursTrackedBar.vue'
import { BOverlay, BCard } from 'bootstrap-vue'

const moment = require('moment')

export default {
  components: {
    BarGraph,
    BOverlay,
    BCard,
  },
  props: {
    startDate: {
      type: [Object, Date, String],
      default: null,
    },
    endDate: {
      type: [Object, Date, String],
      default: null,
    },
    userId: {
      type: [Object, String],
      default: null,
    },
    timezone: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      timeTrackedInfo: [],
      processing: false,
      graphData: {
        categories: [],
        series: [
          { data: [], name: this.$t('Hours') },
        ],
      },
    }
  },
  computed: {
    monthsBetween() {
      const dateStart = moment(this.startDate)
      const dateEnd = moment(this.endDate).subtract('1', 'day')
      const timeValues = []

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        timeValues.push(
          {
            key: dateStart.format('YYYY-MM'),
            label: dateStart.format('MMM-YYYY'),
          },
        )
        dateStart.add(1, 'month')
      }
      return timeValues
    },
  },
  watch: {
    startDate: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    endDate: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    userId: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    timezone: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
  },
  methods: {
    setGraphData(timeTrackedInfo) {
      const categories = []
      const timeData = []
      this.monthsBetween.forEach(months => {
        const seconds = timeTrackedInfo.filter(tracked => tracked.key === months.key)
          .reduce((previous, current) => current.time + previous, 0)
        categories.push(months.label)
        timeData.push(seconds)
      })
      this.graphData = {
        categories,
        series: [
          { data: timeData, name: 'Hours' },
        ],
      }
    },
    getDailyActivityRecords() {
      this.processing = true
      const startTime = `${this.startDate}`
      const endTime = `${this.endDate}`
      useApollo.activities.getAllDailyActivities({
        startTime,
        endTime,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
        first: -1,
      }).then(response => {
        const timeTrackedInfo = []
        response.data.users.data.forEach(users => {
          users.dailyActivities.data.forEach(tracks => {
            timeTrackedInfo.push({
              key: moment(tracks.date).format('YYYY-MM'),
              time: tracks.duration / 3600,
            })
          })
        })
        this.timeTrackedInfo = timeTrackedInfo
        this.setGraphData(timeTrackedInfo)
      }).catch(error => {
        this.showErrorMessage(error)
        console.error(error)
      }).finally(() => { this.processing = false })
    },
  },
}
</script>
