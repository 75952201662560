<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: {
      type: [Object, Array],
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chartOptions() {
      const self = this
      return {
        chart: {
          height: 500,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter() {
            return ''
          },
        },
        yaxis: {
          min: 0,
          max: Math.max(...this.series[0]?.data),
          tickAmount: 6,
          labels: {
            formatter(val) {
              return `${val.toFixed(0)} Hr`
            },
          },
        },
        tooltip: {
          custom({
            series, seriesIndex, dataPointIndex,
          }) {
            const seconds = self.secondsToHms(series[seriesIndex][dataPointIndex] * 3600)
            return `<div class="p-2">
                <b>${self.categories[dataPointIndex]}</b><br/>
                ${seconds}
            </div>`
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
  },
  methods: {
    secondsToHms(d) {
      // eslint-disable-next-line no-param-reassign
      d = Number(d)
      const h = Math.floor(d / 3600)
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor(d % 3600 / 60)
      const s = Math.floor(d % 3600 % 60)

      const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
      const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
      const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
      return hDisplay + mDisplay + sDisplay
    },
  },
}
</script>

<style lang="scss">
.apexcharts-legend-text {
  color: #e6e6e6 !important;
}
</style>
